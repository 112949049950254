<template>
  <a-card bordered="false">
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240); margin-bottom: 35px"
      title="水费记录"
      @back="() => $router.go(-1)"
    >
    </a-page-header>
    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="count_render" slot-scope="text" style="text-align: right; min-width: 100px; max-width: 110px">
        {{ text }}
      </div>
      <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 100px; max-width: 110px">
        {{ (text / 100).toFixed(2) | numberFormat }}
      </div>
      <span slot="color" slot-scope="color">
        <a-tag
          style="height: 15px"
          :color="color"
        >
          {{ '    ' }}
        </a-tag>
      </span>
      <span slot="action" slot-scope="text, record" v-action:delete>
        <template>
          <a-popconfirm
            v-if="record.water_bill_id === -1"
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelet(record)"
          >
            <a>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { water_meter_record_delete, water_meter_record_list } from '@/api/water_meter'

export default {
  name: 'TableList',
  components: {
    STable
  },
  data () {
    return {
      rules: {
        batch: [
          { required: true, message: '请输入', trigger: 'blur' }
        ]
      },
      form: {
        batch: ''
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      loading: false,
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '水表编号',
          dataIndex: 'water_meter_number',
          ellipsis: true
        },
        {
          title: '上期表读数',
          dataIndex: 'last_value',
          scopedSlots: { customRender: 'count_render' },
          align: 'center'
        },
        {
          title: '表读数',
          dataIndex: 'value',
          scopedSlots: { customRender: 'count_render' },
          align: 'center'
        },
        {
          title: '单价',
          dataIndex: 'price',
          scopedSlots: { customRender: 'money_render' },
          align: 'center'
        },
        {
          title: '总价',
          dataIndex: 'amount',
          scopedSlots: { customRender: 'money_render' },
          align: 'center'
        },
        {
          title: '关联状态',
          dataIndex: 'water_bill_id',
          customRender: (text) => text > 0 ? ' 已关联' : '未关联'
        },
        {
          title: '批次',
          dataIndex: 'batch',
          ellipsis: true
        }
        // ,
        // {
        //   title: '操作',
        //   dataIndex: 'action',
        //   scopedSlots: { customRender: 'action' }
        // }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        return water_meter_record_list({ bill_id: this.$route.params.id })
          .then(res => {
            console.log(res.data, '我是数据列表@')
            return res.data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
  },
  methods: {
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleDelet (record) {
      water_meter_record_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')
        this.$refs.table.refresh()
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }
}
</script>
