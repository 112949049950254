import request from '@/utils/request'

const water_meterApi = {
  water_meter_list: '/water_meter/',
  water_meter_create: '/water_meter/',
  water_meter_record_list: '/water_meter/record/',
  water_meter_record_create: '/water_meter/record/',
  water_meter_delete: '/water_meter/',
  water_meter_record_delete: '/water_meter/record/'
}

/**
 * 列表
 */
export function water_meter_list (parameter) {
  return request({
    url: water_meterApi.water_meter_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function water_meter_create (parameter) {
  return request({
    url: water_meterApi.water_meter_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 列表
 */
export function water_meter_record_list (parameter) {
  return request({
    url: water_meterApi.water_meter_record_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function water_meter_record_create (parameter) {
  return request({
    url: water_meterApi.water_meter_record_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function water_meter_delete (water_meter_id) {
  return request({
    url: water_meterApi.water_meter_delete + water_meter_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
/**
 * 删除
 */
export function water_meter_record_delete (water_meter_id) {
  return request({
    url: water_meterApi.water_meter_record_delete + water_meter_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
